<template>
    <section class="banner-wrap two-column-banner">
        <div class="container">
            <div class="row">
            <div class="col-md-18">
                <div class="sectionTitle text-center">{{ $trans("storefront::storefront.best_deals") }} </div>
                 <!--<div class="sectionTitle text-center">{{ trans('storefront::storefront.best_deals') }}</div> -->
            </div>
            <div class="col-md-3 ">
            <div class="dealsimg">
                <img :src="require('../../../images/silver.jpg')" alt="Example Image">
                </div>
            </div>
                <div class="col-md-6 col-sm-9">
                    <a
                        :href="data.banner_1.call_to_action_url"
                        class="banner"
                        :target="
                            data.banner_1.open_in_new_window
                                ? '_blank'
                                : '_self'
                        "   
                    >  
                        <img :src="data.banner_1.image.path" alt="Banner" />
                    </a>
                </div>

                <div class="col-md-6 col-sm-9">
                    <a
                        :href="data.banner_2.call_to_action_url"
                        class="banner"
                        :target="
                            data.banner_2.open_in_new_window
                                ? '_blank'
                                : '_self'
                        "
                    >
                        <img :src="data.banner_2.image.path" alt="Banner" />
                    </a>
                </div>
                <div class="col-md-3">
                 <div class="dealsimg">
                    <img :src="require('../../../images/gold.jpg')" alt="Example Image">
                </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["data"],
};
</script>
