import store from "../../store";
import Errors from "../../Errors";
import GalleryMixin from "./show/mixins/GalleryMixin";
import VariationMixin from "./show/mixins/VariationMixin";
import VariantMixin from "./show/mixins/VariantMixin";
import OptionMixin from "./show/mixins/OptionMixin";
import QuantityMixin from "./show/mixins/QuantityMixin";
import ReviewMixin from "./show/mixins/ReviewMixin";
import ProductCardMixin from "../../mixins/ProductCardMixin";

export default {
    components: {
        VPagination: () => import("../VPagination.vue"),
        RelatedProducts: () => import("./show/RelatedProducts.vue"),
    },

    mixins: [
        GalleryMixin,
        VariationMixin,
        VariantMixin,
        OptionMixin,
        QuantityMixin,
        ReviewMixin,
        ProductCardMixin,
    ],

    props: ["product", "reviewCount", "avgRating"],

    data() {
        return {
            item: {
                ...(this.product.variant ? this.product.variant : this.product),
            },
            oldMediaLength: 0,
            activeVariationValues: {},
            variationImagePath: null,
            price: this.product.formatted_price,
            activeTab: "description",
            currentReviewPage: 1,
            fetchingReviews: false,
            reviews: {},
            addingNewReview: false,
            reviewForm: {},
            cartItemForm: {
                product_id: this.product.id,
                qty: 1,
                variations: {},
                options: {},
            },
            errors: new Errors(),
            userLoggedIn: false, // This should be set based on your actual authentication logic
            userId: null, // Set this with the logged-in user's ID
            form: {
            name: window.userName || null,
            email: window.userEmail || null,
            contact: window.userContact || null,
            user_id: window.userId || null,
            product_id: this.product.id,
            },
            userLoggedIn: !!window.userId,
            loading: false,
            successMessage: '', 
                };
    },

    computed: {
        productUrl() {
            return route("products.show", {
                slug: this.product.slug,
                ...(this.hasAnyVariant && {
                    variant: this.item.uid,
                }),
            });
        },

        isActiveItem() {
            return this.item.is_active === true;
        },

        isAddToCartDisabled() {
            return this.item.is_out_of_stock ?? true;
        },
    },
   
    methods: {
        openInquiryModal() {
            // Use jQuery to show the Bootstrap modal
            $('#inquiryModal').modal('show');
          },
          closeInquiryModal() {
            $('#inquiryModal').modal('hide');
          },
        isMobileDevice() {
            return window.matchMedia("only screen and (max-width: 991px)")
                .matches;
        },

        addToCart() {
            if (this.isAddToCartDisabled) return;

            this.addingToCart = true;

            axios
                .post(route("cart.items.store"), {
                    ...this.cartItemForm,
                    ...(this.hasAnyVariant && { variant_id: this.item.id }),
                })
                .then((response) => {
                    store.updateCart(response.data);
                    
                    $(".header-cart").trigger("click");
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);

                        return;
                    }

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.addingToCart = false;
                });
        },

        initUpSellProductsSlider() {
            $(this.$refs.upSellProducts).slick({
                rows: 0,
                dots: false,
                arrows: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                rtl: window.FleetCart.rtl,
            });
        },
        handleFormSubmit() {
              this.submitForm();
          },
          
          submitForm() {
            if (this.loading) return;
            this.loading = true;
            axios.post(route("storefront.productsindex.saveInquiery"), this.form)
            .then(response => {
                this.loading = false;
                
                //const successMessage = "Inquiry added successfully";
                /* if (window.matchMedia("only screen and (max-width: 768px)").matches) {
                    alert('Inquiry added successfully');
                } */
               
                this.$notify(response.data.message, { position: "top-right", duration: 5000  });

                
                // Display the success message using this.$notify
               // this.$notify(successMessage);
              //console.log('Form submitted successfully');
              this.closeInquiryModal();
              
            })
            .catch(error => {
                this.loading = false;
                this.$notify(error.response.data.message);
             
            });
          },
          resetFormaaaaa() {
            this.form = {
                name: window.userName || '',
                email: window.userEmail || '',
                contact: window.userContact || '',
                user_id: window.userId,
                product_id: this.product.id,
            };
        }
    },
    mounted() {
        // this.$notify('Form submission started...');
        // Reset form when modal is hidden
        $('#inquiryModal').on('hidden.bs.modal', this.resetFormaaaaa);
       
    }
};
