<template>
    <div class="grid-view-products-item">
        <ProductCard :product="product" />
    </div>
</template>

<script>
import ProductCard from "./../../ProductCard.vue";

export default {
    components: { ProductCard },

    props: ["product"],
    hasProduct() {
      // Check if the product prop is not empty
      return this.product !== undefined && Object.keys(this.product).length > 0;
    }
};
</script>
